import Metamask from "../assets/images/download-metamask.png";
import Web3 from "web3";
import BN from "bn.js";

const NETWORKS = {
  1: "mainnet",
  3: "ropsten",
  4: "rinkeby",
  5: "goerli",
  42: "kovan"
}

const BASE_ETHERSCAN_URL = "etherscan.io";
const BASE_RPC_ENDPOINT = "infura.io/v3/17c7a1493a5a460a9c3b021beb6e2253";

const UNITS =
[ "wei", "kwei", "mwei", "gwei", "micro", "milli", "ether", "kether", "mether", "gether", "tether" ];

let abi = {};
let contracts = {};
let currentAccount = {};
let networkId;
let web3Ready = false;

function network() {
  return NETWORKS[networkId];
}

function etherscanURL() {
  let url = BASE_ETHERSCAN_URL;
  if(networkId != 1) {
    url =  NETWORKS[networkId] + "." + url;
  }
  return "https://" + url;
}

function rpcEndpoint() {
  return "https://" + NETWORKS[networkId] + "." + BASE_RPC_ENDPOINT;
}

function lookupAddress(address) {
  let result = address;
  if(registry[address]) {
    result = registry[address];
  };
  return result;
}

function lookupName(name) {
  let result = name;
  Object.keys(registry).forEach(key => {
    if(registry[key] == name) {
      result = key;
    }
  });

  return result;
}

function isAddressValid(address) {
  return address.length == 42 && address.startsWith("0x");
}

function isHashValid(hash) {
  return hash.length == 66 && hash.startsWith("0x");
}

function shortenHash(hash) {
  return hash.substring(0, 6) + "..." + hash.substring(hash.length-4, hash.length);
}

function shortenAddress(address, format) {
  let result = address;
  if(address && address.length == 42 && address.startsWith("0x")) {
    result = address.substring(0, 8) + "..." + address.substring(42-6, 42);

    if(format) {
      return "<a href='"+etherscanURL()+"/address/"+address+"' target='_blank'>" + result + "</a>";
    }
  }
  return result;
}

function txHashUrl(txHash) {
  return etherscanURL()+"/tx/"+txHash;
}

function formatAddress(address) {
  return "<a href='"+etherscanURL()+"/address/"+address+"' target='_blank'>" + address + "</a>";
}

function loadContract(contractABI, address) {
  window.web3 = new Web3(rpcEndpoint());
  let instance = new web3.eth.Contract(contractABI.abi, address, { from: currentAccount.address });
  let name = contractABI.contractName;
  contracts[name] = {
    name: name,
    address: address,
    instance: instance
  };
  return contracts[name];
}

function contract(name) {
  return contracts[name];
}

function loadABI(abiNames) {
  let loadingABIPromises = [];
  abiNames.forEach(item => {
    loadingABIPromises.push(
      import("../assets/abi/"+item+".json").then((imported) => {
        abi[item] = imported;
      }).catch((error) => {
        console.error(item + " is invalid!");
        console.error(error);
      })
    );
  });
  return Promise.all(loadingABIPromises);
}

function newContract(contractABI, parameters) {
  let contract = web3.eth.contract(contractABI.abi);
  let payload = { data: contractABI.bytecode };

  let hashPromiseCallbacks = {};
  let hashPromise = new Promise((resolve, reject) => {
    hashPromiseCallbacks.resolve = resolve;
    hashPromiseCallbacks.reject = reject;
  });

  let minedPromiseCallbacks = {};
  let minedPromise = new Promise((resolve, reject) => {
    minedPromiseCallbacks.resolve = resolve;
    minedPromiseCallbacks.reject = reject;
  });

  let args = [].concat(parameters);
  args.push(payload);

  let callback = (error, data) => {
    if(data) {
      if(data.address) {
        loadContract(contractABI, data.address);
        minedPromiseCallbacks.resolve(data);
      } else {
        hashPromiseCallbacks.resolve(data.transactionHash);
      }
    } else {
      console.error(error);
      hashPromiseCallbacks.reject(error);
      minedPromiseCallbacks.reject(error);
    }
  };
  args.push(callback);
  contract.new.apply(contract, args);
  
  return {
    hashPromise: hashPromise,
    minedPromise: minedPromise
  };
}

function callMethod(method, parameters) {
  let args = [].concat(parameters);

  let hashPromiseCallbacks = {};
  let hashPromise = new Promise((resolve, reject) => {
    hashPromiseCallbacks.resolve = resolve;
    hashPromiseCallbacks.reject = reject;
  });

  let minedPromiseCallbacks = {};
  let minedPromise = new Promise((resolve, reject) => {
    minedPromiseCallbacks.resolve = resolve;
    minedPromiseCallbacks.reject = reject;
  });

  let callback = (error, data) => {
    if(data) {
      hashPromiseCallbacks.resolve(data);
      waitForReceipt(data).then((receipt) => {
        console.log(receipt);
        minedPromiseCallbacks.resolve(receipt);
      });
    } else {
      console.error(error);
      hashPromiseCallbacks.reject(error);
      minedPromiseCallbacks.reject(error);
    }
  };

  args.push(callback);
  method.apply(this, args);

  return {
    hashPromise: hashPromise,
    minedPromise: minedPromise
  };
}

function waitForReceipt(hash) {
  return new Promise(function(resolve, reject) {
    let interval = 1000;
    let timeout = 100000;
    console.log("checking for tx "+hash);
    let timer = window.setInterval(() => {
      web3.eth.getTransactionReceipt(hash, function (error, receipt) {
        if (error) {
          console.error(error);
          reject(error);
        }
        if (receipt !== null) {
          console.log("Receipt:");
          console.log(receipt);
          console.log("tx mined !");
          // Transaction went through
          window.clearInterval(timer);
          resolve(receipt);
        } else {
          timeout -= interval;
          if(timeout <= 0) {
            console.log("Timeout reached!");
            // timeout
            window.clearInterval(timer);
            reject(hash);
          }
        }
      });
    }, interval);
  });
}

let web3Loading = Promise.resolve().then(() => {
  window.web3 = new Web3(rpcEndpoint());
  let network = new URL(window.location.href).searchParams.get("network");  
  for (let id in NETWORKS) {
    if (network == id || network == NETWORKS[id]) {
      networkId = id;
    }
  }
  networkId = (networkId) ? networkId : 1;

  if (!window.ethereum) {
    console.log("Connecting to " + NETWORKS[networkId]);
    window.web3 = new Web3(rpcEndpoint());
    return Promise.resolve(window.web3);
  }

  window.web3 = new Web3(window.ethereum);
  let retrieveMainAccount = () => {
    return web3.eth.getAccounts().then((accounts) => {
      console.log(accounts);
      if (accounts && accounts.length > 0) {
        currentAccount.address = accounts[0];
        return web3.eth.getBalance(accounts[0]).then((balance) => {
          currentAccount.balance = balance;
          return accounts[0];
        });
      }
    });
  };

  return web3.eth.net.getId().then((id) => {
    if (id != networkId) {
      console.warn("Metamask should be connected instead to " + NETWORKS[networkId]);
      console.warn("Using infura endpoint directly...");
      window.web3 = new Web3(rpcEndpoint());
      return window.web3;
    }

    if (!window.ethereum.selectedAddress) {
      console.info("Metamask is not yet enabled!");
      return ethereum.enable().then((err, data) => {
        if (data) {
          return retrieveMainAccount();
        } else {
          console.error(err);
        }
      });
    } else {
      return retrieveMainAccount();
    }
  }).finally(() => {
    web3Ready = true;
    return web3;
  });
});

function reloadOnMetamaskChanges() {
  if(window.ethereum) {
    window.ethereum.on("accountsChanged", function (accounts) {
      if(web3Ready) {
        // Time to reload your interface with accounts[0]!
        location.reload();
      }
    });
    
    window.ethereum.on("chainChanged", function (netId) {
      if (web3Ready) {
        // Time to reload your interface with netId
        location.reload()
      }
    });  
  }
}

function displayUnit(ethValue) {
  let result;
  if(!ethValue || ethValue == 0) {
    return { value: Number(0), unit: "ether" };
  }

  let value = new BN(ethValue);
  UNITS.forEach((unit, i) => {
    if(!result && value < 1000) {
      result = {
        value: (Number(ethValue) / 10**(3*i)),
        unit: unit
      };
    } else {
      if(value >= 1000) {
        value = value.div(new BN(1000));
      }
    }
  })
  return result;
}


export { lookupName, network, etherscanURL, lookupAddress, isAddressValid, isHashValid, shortenHash,
  shortenAddress, txHashUrl, formatAddress, loadContract, abi, loadABI, contract, web3Loading, 
  reloadOnMetamaskChanges, newContract, callMethod, waitForReceipt, currentAccount, displayUnit };
