import "./styles/common.scss";
import "./styles/index.scss";
import { shortenAddress, formatAddress, isAddressValid, txHashUrl, web3Loading, network, currentAccount } from "./libs/ethereum";

document.addEventListener("DOMContentLoaded", () => {

web3Loading.then((data) => {
 }).finally(() => {
    var loading = document.getElementById("loading");
    loading.style.display = "none";

    var setup = document.getElementById("content");
    setup.style.display = "block";

    let demoContracts = document.getElementsByClassName("demo-contracts");
    let currentNetwork = network();

    for(let i=0; i < demoContracts.length; i++) {
      let item = demoContracts.item(i);
      if(item.id == currentNetwork) {
        item.style.display = "block";
      } else {
        item.style.display = "none";
      }
    }
  });

  console.log("Started...");
});

